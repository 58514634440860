import React, { useState, useEffect, useMemo } from "react";

/** Utils */
import useWindowDimensions from "../../services/utils/Dimensions";
import {
	walletDuplicates,
	validateWalletInput,
} from "../../services/walletValidate.service";
import { fetchNFTsFirebase } from "../../services/fetchNFTs.service";
import ReactGA from "react-ga4";

import {
	PublicKey,
} from "@solana/web3.js";


/** Web3 Integration */
import { useMetaplex } from "../../components/metaplex-connection/useMetaplex";

/** Collage Instruction Components (left side of screen) */
import WalletInput from "../../components/collage-generator/WalletInput";
import AvailableCollections from "../../components/collage-generator/AvailableCollections";
import AvailableNFTs from "../../components/collage-generator/AvailableNFTs";
import GridSelection from "../../components/collage-generator/GridSelection";

/** Collage Results Components (right side of screen) */
import { ResultsHeader } from "../../components/collage-generator/collage-results/ResultsHeader";
import DesktopCollage from "../../components/collage-generator/collage-results/DesktopCollage";
import ResultsFooter from "../../components/collage-generator/collage-results/ResultsFooter";
import MobileCollage from "../../components/collage-generator/collage-results/MobileCollage";

/** Need to deep clone filtered traits and adjust them */
var cloneDeep = require("lodash.clonedeep");

export const CollageGeneratorPage = () => {
	const { metaplex } = useMetaplex();
	const windowDimensions = useWindowDimensions();

	/** Wallet Input Variables */
	// Stores the wallet addresses inputted by the user
	const [walletList, setWalletList] = useState([""]);

	// Used to display any wallet error messages or when reaches max wallets
	const [errorWalletInputMsg, setErrorWalletInputMsg] = useState("");
	const [loadingNfts, setLoadingNfts] = useState(false); // Displays loading spinner when loading NFTs for wallet(s)
	const [allNFTs, setAllNFTs] = useState([]); // Used to hold all NFTs for the wallet(s)

	/** Available Collections Variables */
	const [availableCollections, setAvailableCollections] = useState([]); // Used to hold all available collections for the wallet
	const [viewAvailableCollections, setViewAvailableCollections] =
		useState(false); // Whether to show the user the section of all the MBB NFTs that they can select

	/** Available NFTs Variables */
	const [viewableNfts, setViewableNfts] = useState([]); // Used to hold all filtered NFTs for the wallet(s)
	const [loadingCollections, setLoadingCollections] = useState(false);
	const [selectedCollections, setSelectedCollections] = useState([]);
	const [selectedCollectionImages, setSelectedCollectionImages] = useState([]);
	const [selectedNfts, setSelectedNfts] = useState([]); // Stores the specific NFTs selected by the user for the collage
	const [maxSelectedNftsMsg, setMaxSelectedNftsMsg] = useState("");
	const [totalNFTsFetching, setTotalNFTsFetching] = useState(0);
	const [selectedDropdownCollection, setSelectedDropdownCollection] =
		useState(null);
	const [viewCollectionLogos, setViewCollectionLogos] = useState(false);
	const [viewCollectionSorting, setViewCollectionSorting] = useState(false);

	// Whether to show the user the section of all the MBB NFTs that they can select
	const [viewAllMbbNfts, setViewAllMbbNfts] = useState(false);

	/** Traits Variables */
	const [traitTypes, setTraitTypes] = useState([]);
	const [traitNames, setTraitNames] = useState([]);
	const [traitsAll, setTraitsAll] = useState([]);
	const [filteredTraits, setFilteredTraits] = useState({});

	/** Grid Size Variables */
	const [numOfNftsPerCol, setNumOfNftsPerCol] = useState(-1); // Stores the number of NFTs to display per row** on the collage
	const [numOfNftsPerRow, setNumOfNftsPerRow] = useState(-1); // Stores the number of NFTs to display per row** on the collage
	const [autoGrid, setAutoGrid] = useState(true);

	/** Collage Result Variables / Options */
	const [displayBorder, setDisplayBorder] = useState(true);
	const [displayBorderColorChange, setDisplayBorderColorChange] =
		useState(false);
	const [displayBkgColorChange, setDisplayBkgColorChange] = useState(false);
	const [borderCustomColor, setBorderCustomColor] = useState();
	const [borderWidth, setBorderWidth] = useState(1);
	const [textSize, setTextSize] = useState(12);
	const [bkgCustomColor, setBkgCustomColor] = useState();
	const [grayscaleOn, setGrayscaleOn] = useState(false);
	const [collageText, setCollageText] = useState("");
	const [verticalAlignText, setVerticalAlignText] = useState("bottom");
	const [horizontalAlignText, setHorizontalAlignText] = useState("right");
	const [displayPrices, setDisplayPrices] = useState(false);
	const [defaultPriceText, setDefaultPriceText] = useState("");
	const [otcPosition, setOtcPosition] = useState("top");
	const [otcSize, setOtcSize] = useState(100);

	/** Other Variables */
	const [displayWatermark, setDisplayWatermark] = useState(true);
	const [mbbHolder, setMbbHolder] = useState(false);
	const displayCollage = useMemo(() => {
		return selectedNfts && selectedNfts?.length > 0 && numOfNftsPerCol != -1;
	}, [selectedNfts, numOfNftsPerCol]);
	/** NFT dragging in drag and drop and used to edit OTC SOL price for active NFT */
	const [lastActiveId, setLastActiveId] = useState(null);
	const [priceMap, setPriceMap] = useState(null);

	useEffect(() => {
		// Saves pageview to analytics
		ReactGA.send({
			hitType: "pageview",
			page_title: "Collage Page",
			page: window.location.pathname,
		});
	}, []);

	/** When a user selects or deselects a NFT collection to view */
	useEffect(() => {
		if (selectedCollections) {
			if (selectedCollections.length > 0) {
				setViewAllMbbNfts(true);
			} else {
				setViewAllMbbNfts(false);
				setSelectedNfts([]);
				setLoadingNfts(false);
				setNumOfNftsPerCol(-1);
				setNumOfNftsPerRow(-1);
				setViewAllMbbNfts(false);
				setTraitTypes([]);
				setTraitNames([]);
				setTraitsAll([]);
				setFilteredTraits({});
				setDisplayBkgColorChange(false);
				setDisplayBorderColorChange(false);
				setDisplayPrices(false);
			}

			const newNftsList = [];
			for (let i = 0; i < allNFTs.length; i++) {
				if (selectedCollections.includes(allNFTs[i].verifiedCreatorAddress)) {
					newNftsList.push(allNFTs[i]);
				}
			}

			fetchTraitTypes(newNftsList);
			clearFilteredTraits();

			resetViewableNfts();

			// Account for collection logos.
			setSelectedNfts(
				selectedNfts.filter((w) => {
					const isActiveLogo = selectedCollectionImages
						.map((col) => col.image)
						.includes(w.image);

					if (!isActiveLogo) {
						for (let index = 0; index < newNftsList.length; index++) {
							// If select NFT is viewable, deselect it
							if (newNftsList[index]?.collectionDetails?.image === w.image) {
								return true;
							}
						}
						return false;
					} else {
						return true;
					}
				})
			);
		}
	}, [selectedCollections]);

	// If there is an error with the user's inputted wallets
	const throwWalletErrorMsg = (msg) => {
		setErrorWalletInputMsg(msg);
		setViewAllMbbNfts(false);
		setSelectedNfts([]); // resets selected NFTs
		setLoadingNfts(false);
		setViewAvailableCollections(false);
		setLoadingCollections(false);
	};

	// User clicks to generate NFTs, resets data
	const generateNfts = () => {
		if (walletList.length >= 1 && walletList[0] != "") {
			//setViewableNfts(allNFTs);
			setFilteredTraits({});
			setSelectedNfts([]); // resets selected NFTs
			setLoadingNfts(true);
			setNumOfNftsPerCol(-1);
			setNumOfNftsPerRow(-1);
			setErrorWalletInputMsg("");
			setAvailableCollections([]);
			setAllNFTs([]);
			setSelectedCollections([]);
			setSelectedCollectionImages([]);
			setViewAvailableCollections(false);
			setLoadingCollections(true);
			setTotalNFTsFetching(0);
			setAutoGrid(true);
			setViewCollectionSorting(false);
			// Begin fetching NFTs
			fetchAllNfts();
		} else {
			throwWalletErrorMsg("Please enter at least one wallet address.");
			setLoadingCollections(false);
		}
	};

	/** Used to fetch all NFTs to display as options */
	const fetchAllNfts = async () => {
		try {
			const duplicates = await walletDuplicates(walletList);
			if (duplicates) {
				throwWalletErrorMsg("Error: Duplicate wallet addresses.");
				return;
			}

			let availCollections = [];
			let allNFTsList = [];
			let totalNFTsFetched = 0;

			let metaplexNFTs = [];

			// TODO: remove any existing wallets already in fetchedWalletList.
			// -> need to tie wallets to NFTs so can remove out NFTs no longer active if a wallet is removed...
			// Verified NFTs -> attach wallet address
			// Verified Collections

			let walletsParsed = [];

			for (let index = 0; index < walletList.length; index++) {
				// Validate each wallet address input before fetching NFTs,
				let validWallet = await validateWalletInput(walletList[index]);
				if (!validWallet) {
					// If invalid, returns error message.
					throwWalletErrorMsg("Error: Invalid wallet address(es).");
					return;
				}

				walletsParsed.push(walletList[index]?.replaceAll(" ", ""));
			}

			const resp = await fetchNFTsFirebase(walletsParsed);

			let { verifiedCollections, verifiedNFTs } = resp.data;

			for (let k = 0; k < verifiedNFTs.length; k++) {
				if (verifiedNFTs[k]?.collectionDetails?.image) {
					verifiedNFTs[k].collectionDetails.image =
						verifiedNFTs[k]?.collectionDetails?.image + "?z" + k.toString();
				}
			}

			availCollections = verifiedCollections;

			if (!verifiedCollections || verifiedNFTs.length == 0) {
				throwWalletErrorMsg(
					"Error: One or more wallet address(es) does not contain any available NFTs."
				);
				return;
			}

			setLoadingNfts(false);
			setViewableNfts([]);
			setSelectedCollections([]);
			setSelectedCollectionImages([]);

			allNFTsList = verifiedNFTs;
			allNFTsList.sort((a, b) =>
				a.verifiedCreatorAddress.localeCompare(b.verifiedCreatorAddress)
			);
			setAvailableCollections(availCollections);
			setAllNFTs(allNFTsList);
			setViewAvailableCollections(true);
			setLoadingCollections(false);
		} catch (error) {
			setLoadingNfts(false);
			throwWalletErrorMsg("Error: Invalid wallet address.");
		}
	};

	/** Used to fetch all available trait types based on the user's selected collections */
	const fetchTraitTypes = (fetchedNFTs) => {
		if (fetchedNFTs.length == 0) return;
		const traits = [];
		if (fetchedNFTs && fetchedNFTs.length > 0) {
			// Loops throuh all individual MBB NFTs fetched from the user's wallet(s)
			for (let i = 0; i < fetchedNFTs.length; i++) {
				if (
					fetchedNFTs[i]?.collectionDetails?.attributes?.length > 0 &&
					fetchedNFTs[i].verifiedCreatorAddress !== "other"
				) {
					for (
						let j = 0;
						j < fetchedNFTs[i].collectionDetails.attributes.length;
						j++
					) {
						let traitType =
							fetchedNFTs[i].collectionDetails.attributes[j].trait_type ||
							fetchedNFTs[i].collectionDetails.attributes[j].traitType;
						let value = fetchedNFTs[i].collectionDetails.attributes[j].value;

						// If the trait type is not already in the list, add it.
						if (!traits[fetchedNFTs[i].verifiedCreatorAddress]?.[traitType]) {
							if (!traits[fetchedNFTs[i].verifiedCreatorAddress]) {
								traits[fetchedNFTs[i].verifiedCreatorAddress] = {};
							}

							traits[fetchedNFTs[i].verifiedCreatorAddress][traitType] = [
								value,
							];
						} else {
							// If the trait type is in the list, but the value is not, add the value as an option
							if (
								!traits[fetchedNFTs[i].verifiedCreatorAddress][
									traitType
								].includes(value)
							) {
								traits[fetchedNFTs[i].verifiedCreatorAddress][traitType] = [
									...traits[fetchedNFTs[i].verifiedCreatorAddress][traitType],
									value,
								];
							}
						}
					}
				}
			}
		}
		setTraitsAll(traits);
	};

	/**
	 * Can check / uncheck an NFT collection to show that
	 * collection's NFTs in the available NFTs
	 */
	function selectCollection(collectionAddress, collectionImgAddress) {
		if (selectedCollections.includes(collectionAddress)) {
			// If collection is already selected, deselect it
			const colIndexOf = selectedCollections.indexOf(collectionAddress);
			if (colIndexOf > -1) {
				setSelectedCollections(
					selectedCollections.filter((w, i) => i !== colIndexOf)
				);

				if (selectedCollectionImages.length == 2) {
					setViewCollectionSorting(false);
				}
				// Deselect collection image.
				setSelectedCollectionImages(
					selectedCollectionImages.filter(
						(w, i) => w?.verifiedCreatorAddress !== collectionAddress
					)
				);
			}
		} else {

			// Select a collection to add
			setSelectedCollections([...selectedCollections, collectionAddress]);
			setSelectedCollectionImages([
				...selectedCollectionImages,
				{
					verifiedCreatorAddress: collectionAddress,
					image: collectionImgAddress,
				},
			]);
		}
	}

	/** Whether to display collection logos as an option or not */
	function toggleCollectionLogos() {
		if (viewCollectionLogos === false) {
			// Turn on collection logos
			setViewCollectionLogos(!viewCollectionLogos);
		} else {
			// Turn off collection logos
			setViewCollectionLogos(!viewCollectionLogos);

			// setSelectedNfts(
			// 	selectedNfts.filter(
			// 		(w) => !selectedCollectionImages.some((nft) => nft?.image !== w.image)
			// 	)
			// );

			// Turn off collection logos
			const imagesList = selectedCollectionImages.map((elem) => elem.image);
			setSelectedNfts(
				selectedNfts.filter((w) => {
					if (imagesList.includes(w.image)) {
						return false;
					} else {
						return true;
					}
				})
			);
		}
	}

	/** Used to select a specific NFT to add to the collage */
	function selectAnNft(nft) {
		// If NFT is already selected, unselect it
		let alreadySelected = false;
		let currentIndex = -1;

		for (let i = 0; i < selectedNfts.length; i++) {
			if (selectedNfts[i].image === nft?.collectionDetails?.image) {
				alreadySelected = true;
				currentIndex = i;
				break;
			}
		}

		if (alreadySelected) {
			if (currentIndex > -1) {
				if (selectedNfts.length <= 3) {
					// if they are deselecting all nfts, reset the grid size to prevent 1x_
					setNumOfNftsPerCol(-1);
					setNumOfNftsPerRow(-1);
				}
				setSelectedNfts(selectedNfts.filter((w, i) => i !== currentIndex));
				// Disable max selected msg
				setMaxSelectedNftsMsg("");
			}
		} else {
			if (selectedNfts.length == 1) {
				// if they are selecting their second nft, reset the grid size to prevent 1x_
				setNumOfNftsPerCol(-1);
				setNumOfNftsPerRow(-1);
			}
			if (selectedNfts.length < 500) {
				setSelectedNfts([
					...selectedNfts,
					{
						image: nft?.collectionDetails?.image,
						verifiedCreatorAddress: nft?.verifiedCreatorAddress,
					},
				]);
			}
			if (selectedNfts.length >= 499) {
				setMaxSelectedNftsMsg(
					"Note: A maximum of 500 NFTs can be selected at one time."
				);
			}
		}
	}

	/** Used to select a specific Collection Image to add to the collage */
	function selectAnCollectionImg(col) {
		// If NFT is already selected, unselect it
		let alreadySelected = false;
		let currentIndex = -1;

		for (let i = 0; i < selectedNfts.length; i++) {
			if (selectedNfts[i].image === col?.image) {
				alreadySelected = true;
				currentIndex = i;
				break;
			}
		}

		if (alreadySelected) {
			if (currentIndex > -1) {
				if (selectedNfts.length <= 3) {
					// if they are deselecting all nfts, reset the grid size to prevent 1x_
					setNumOfNftsPerCol(-1);
					setNumOfNftsPerRow(-1);
				}
				setSelectedNfts(selectedNfts.filter((w, i) => i !== currentIndex));

				// Disable max selected msg
				setMaxSelectedNftsMsg("");
			}
		} else {
			if (selectedNfts.length == 1) {
				// if they are selecting their second nft, reset the grid size to prevent 1x_
				setNumOfNftsPerCol(-1);
				setNumOfNftsPerRow(-1);
			}
			if (selectedNfts.length < 500) {
				setSelectedNfts([
					...selectedNfts,
					{
						image: col?.image,
						verifiedCreatorAddress: col?.verifiedCreatorAddress,
					},
				]);
			}
			if (selectedNfts.length >= 499) {
				setMaxSelectedNftsMsg(
					"Note: A maximum of 500 NFTs can be selected at one time."
				);
			}
		}
	}

	/** Used to select all NFTs (and collection images) and add them all to the collage */
	function selectAll() {
		if (viewableNfts?.length > 0) {
			// setSelectedNfts([]); // TEMP
			const temp = cloneDeep(selectedNfts); //[];

			// Select all collection logos if enabled
			if (viewCollectionLogos) {
				for (let index = 0; index < selectedCollectionImages.length; index++) {
					// Add collection logo if not already selected
					if (
						!selectedNfts
							.map((elem) => elem.image)
							.includes(selectedCollectionImages[index].image) &&
						temp.length < 500
					) {
						temp.push({
							image: selectedCollectionImages[index].image,
							verifiedCreatorAddress:
								selectedCollectionImages[index].verifiedCreatorAddress,
						});
					}
					if (temp.length >= 499) {
						setMaxSelectedNftsMsg(
							"Note: A maximum of 500 NFTs can be selected at one time."
						);
					}
				}
			}

			// Select all viewable NFTs
			for (let index = 0; index < viewableNfts.length; index++) {
				// Add viewable NFT if not already selected
				if (
					!selectedNfts
						.map((elem) => elem.image)
						.includes(viewableNfts[index]?.collectionDetails?.image) &&
					temp.length < 500
				) {
					temp.push({
						image: viewableNfts[index]?.collectionDetails?.image,
						verifiedCreatorAddress: viewableNfts[index]?.verifiedCreatorAddress,
					});
				}
				if (temp.length >= 499) {
					setMaxSelectedNftsMsg(
						"Note: A maximum of 500 NFTs can be selected at one time."
					);
				}
			}

			setSelectedNfts(temp);

			// If the user only had 1 NFT per column selected, reset the grid size
			if (temp.length > 1 && numOfNftsPerCol == 1) {
				setNumOfNftsPerCol(-1);
				setNumOfNftsPerRow(-1);
			}
		}
	}

	/** Used to deselect all viewable NFTs (and collection images) */
	function deselectAll() {
		if (viewableNfts?.length > 0) {
			// Disable max selected msg
			setMaxSelectedNftsMsg("");

			// Only deselects all selected NFTs that are currently viewable on the filters screen
			setSelectedNfts(
				selectedNfts.filter((w) => {
					if (
						!selectedCollectionImages
							.map((elem) => elem.image)
							.includes(w.image)
					) {
						for (let index = 0; index < viewableNfts.length; index++) {
							// If select NFT is viewable, dselect it
							if (viewableNfts[index]?.collectionDetails?.image === w.image) {
								return false;
							}
						}
						return true;
					} else {
						return false;
					}
				})
			);
		}
	}

	/** Used to clear all and restart the collage process */
	function restartCollage() {
		setWalletList([""]);
		setViewableNfts([]);
		setSelectedNfts([]);
		setLoadingNfts(false);
		setNumOfNftsPerCol(-1);
		setNumOfNftsPerRow(-1);
		setViewAllMbbNfts(false);
		setTraitTypes([]);
		setTraitNames([]);
		setFilteredTraits({});
		setSelectedCollections([]);
		setSelectedCollectionImages([]);
		setAvailableCollections([]);
		setViewAvailableCollections(false);
		setGrayscaleOn(false);
		setCollageText("");
		setDisplayWatermark(true);
		setMbbHolder(false);
		setDisplayBkgColorChange(false);
		setDisplayBorderColorChange(false);
	}

	// Used to filter viewable NFTs by a trait
	function selectFilter(result, selectedTraitName, selectedDropdownCollection) {
		if (result.length == 0) {
			// remove the individual trait from the list
			const temp = cloneDeep(filteredTraits);
			delete temp[selectedDropdownCollection.value][selectedTraitName];
			if (Object.keys(temp[selectedDropdownCollection.value]).length === 0) {
				delete temp[selectedDropdownCollection.value];
			}

			// no other filtered traits so clear filters
			if (Object.keys(temp).length === 0) {
				clearFilteredTraits();
				return;
			} else {
				// set remaining filtered traits
				setFilteredTraits(temp);
				filteredNfts(temp);
			}
		} else {
			// add the individual trait to the filtered list
			var vals = Object.keys(result).map(function (key) {
				return result[key].value;
			});
			// setSelectedNfts([]); // TEMP
			if (filteredTraits == null) {
				const temp = {};
				temp[selectedDropdownCollection.value] = { [selectedTraitName]: vals };
				setFilteredTraits(temp);
				filteredNfts(temp);
			} else {
				const temp = filteredTraits;
				if (!temp[selectedDropdownCollection.value]) {
					temp[selectedDropdownCollection.value] = {};
				}
				temp[selectedDropdownCollection.value][selectedTraitName] = vals;
				setFilteredTraits(temp);
				filteredNfts(temp);
			}
		}
	}

	/** Resets the NFTs the user can add to the collage (called when remove all trait filters or deselect all collections) */
	function resetViewableNfts() {
		const newNftsList = [];
		for (let i = 0; i < allNFTs.length; i++) {
			if (selectedCollections.includes(allNFTs[i].verifiedCreatorAddress)) {
				newNftsList.push(allNFTs[i]);
			}
		}
		setViewableNfts(newNftsList);
	}

	/** Used to clear all filtered traits that were selected and show all NFTs */
	function clearFilteredTraits() {
		resetViewableNfts();
		setFilteredTraits({});
	}

	/** Used to filter the NFTs available based on the filters selected */
	function filteredNfts(filterTraitsNew) {
		const filterNfts = [];

		// If no filtered traits are selected, show all NFTs for that collection?
		if (
			!filterTraitsNew[selectedDropdownCollection.value] ||
			Object.keys(filterTraitsNew[selectedDropdownCollection.value]).length ===
				0
		) {
			for (let i = 0; i < allNFTs.length; i++) {
				// If the NFT does belong to the NFT collection being trait filtered,
				// and has trait attributes to it
				if (
					allNFTs[i]?.collectionDetails?.attributes &&
					selectedDropdownCollection.value == allNFTs[i].verifiedCreatorAddress
				) {
					filterNfts.push(allNFTs[i]);
				}
			}
		} else {
			// Loop through all NFTs
			for (let i = 0; i < allNFTs.length; i++) {
				// If the NFT does belong to the NFT collection being trait filtered,
				// and has trait attributes to it
				if (
					allNFTs[i]?.collectionDetails?.attributes &&
					filterTraitsNew[selectedDropdownCollection.value] &&
					selectedDropdownCollection.value == allNFTs[i].verifiedCreatorAddress
				) {
					// Loop through all filtered traits to ensure each NFT has
					// all the selected traits
					let k = 0;
					for (const [key, valuesArr] of Object.entries(
						filterTraitsNew[selectedDropdownCollection.value]
					)) {
						let nftHasFilteredTraitAndValue = false;

						for (
							let j = 0;
							j < allNFTs[i].collectionDetails.attributes.length;
							j++
						) {
							// If the NFT has the filtered trait and value
							if (
								(allNFTs[i].collectionDetails.attributes[j].trait_type == key ||
									allNFTs[i].collectionDetails.attributes[j].traitType ==
										key) &&
								valuesArr.includes(
									allNFTs[i].collectionDetails.attributes[j].value
								)
							) {
								nftHasFilteredTraitAndValue = true;
								break;
							}
						}
						// If the NFT doesn't have the filtered trait or value, go to the next NFT
						if (!nftHasFilteredTraitAndValue) {
							break;
						} else if (
							nftHasFilteredTraitAndValue &&
							k ==
								Object.keys(filterTraitsNew[selectedDropdownCollection.value])
									.length -
									1
						) {
							// If the NFT has all the filtered traits and values, add it to the filtered NFTs
							filterNfts.push(allNFTs[i]);
							continue;
						}
						k += 1;
					}
				}
			}
		}

		// Loop through all viewableNfts not being filtered and added
		for (let i = 0; i < viewableNfts.length; i++) {
			// If the viewable NFT doesn't belong to the NFT collection that is currently being
			// trait filtered, just leave it
			if (
				selectedDropdownCollection.value !=
				viewableNfts[i].verifiedCreatorAddress
			) {
				filterNfts.push(viewableNfts[i]);
				continue;
			}
		}
		setViewableNfts(filterNfts);
	}

	useEffect(() => {
		if (selectedCollectionImages.length >= 2 && viewCollectionSorting == true) {
			const sortedViewableList = [];
			for (let i = 0; i < selectedCollectionImages.length; i++) {
				for (let j = 0; j < selectedNfts.length; j++) {
					if (
						selectedNfts[j].verifiedCreatorAddress ==
						selectedCollectionImages[i].verifiedCreatorAddress
					) {
						sortedViewableList.push(selectedNfts[j]);
					}
				}
			}
			// Add any missing viewable nfts
			for (let j = 0; j < selectedNfts.length; j++) {
				if (!sortedViewableList.includes(selectedNfts[j])) {
					sortedViewableList.push(selectedNfts[j]);
				}
			}
			setSelectedNfts(sortedViewableList);
		}
	}, [selectedCollectionImages, viewCollectionSorting]);

	return (
		<div>
			<div className="flex flex-col mt-[87px] justify-center items-center lg:flex-row lg:justify-start lg:items-start lg:mt-[96px]">
				<div className="flex flex-col lg:flex lg:w-1/2 lg:border-r-2 lg:min-h-screen lg:border-navy-blue">
					<div className="flex flex-col text-center justify-center items-center pt-6">
						<WalletInput
							{...{
								walletList,
								setWalletList,
								generateNfts,
								errorWalletInputMsg,
								restartCollage,
								loadingCollections,
								totalNFTsFetching,
							}}
						/>
					</div>

					{viewAvailableCollections && (
						<AvailableCollections
							{...{
								availableCollections,
								selectCollection,
								selectedCollections,
							}}
						/>
					)}

					{viewAllMbbNfts && (
						<AvailableNFTs
							{...{
								loadingNfts,
								traitNames,
								traitTypes,
								selectFilter,
								filteredTraits,
								viewableNfts,
								setViewableNfts,
								selectedNfts,
								setSelectedNfts,
								allNFTs,
								selectAnNft,
								selectAll,
								setNumOfNftsPerCol,
								setNumOfNftsPerRow,
								clearFilteredTraits,
								selectedCollections,
								availableCollections,
								setTraitTypes,
								setTraitNames,
								traitsAll,
								selectedDropdownCollection,
								setSelectedDropdownCollection,
								viewCollectionLogos,
								toggleCollectionLogos,
								selectedCollectionImages,
								selectAnCollectionImg,
								displayWatermark,
								mbbHolder,
								deselectAll,
								setFilteredTraits,
								maxSelectedNftsMsg,
							}}
						/>
					)}

					{selectedNfts && selectedNfts?.length > 0 && (
						<GridSelection
							{...{
								selectedNfts,
								numOfNftsPerCol,
								setNumOfNftsPerCol,
								numOfNftsPerRow,
								setNumOfNftsPerRow,
								autoGrid,
								setAutoGrid,
							}}
						/>
					)}
				</div>

				{(windowDimensions.width >= 1024 || displayCollage) && (
					<div
						className="flex flex-col text-center justify-center items-center 
					lg:w-1/2 lg:fixed lg:justify-start lg:right-0 lg:top-0 lg:h-screen lg:overflow-scroll lg:mt-[96px] lg:py-[24px]"
					>
						<ResultsHeader
							{...{
								displayCollage,
								displayBorder,
								setDisplayBorder,
								displayPrices,
								setDisplayPrices,
								displayBkgColorChange,
								setDisplayBkgColorChange,
								displayBorderColorChange,
								setDisplayBorderColorChange,
								borderWidth,
								setBorderWidth,
								textSize,
								setTextSize,
								borderCustomColor,
								setBorderCustomColor,
								bkgCustomColor,
								setBkgCustomColor,
								grayscaleOn,
								setGrayscaleOn,
								collageText,
								setCollageText,
								verticalAlignText,
								setVerticalAlignText,
								horizontalAlignText,
								setHorizontalAlignText,
								otcPosition,
								setOtcPosition,

								selectedNfts,
								numOfNftsPerCol,
								numOfNftsPerRow,

								defaultPriceText,
								setDefaultPriceText,

								lastActiveId,
								setLastActiveId,
								priceMap,
								setPriceMap,

								viewCollectionSorting,
								setViewCollectionSorting,
								selectedCollectionImages,
								setSelectedCollectionImages,
								otcSize,
								setOtcSize,
							}}
						/>

						{displayCollage && (
							<>
								{windowDimensions.width > 768 ? (
									<DesktopCollage
										{...{
											selectedNfts,
											setSelectedNfts,
											bkgCustomColor,
											numOfNftsPerCol,
											numOfNftsPerRow,
											displayBorder,
											displayPrices,
											displayWatermark,
											mbbHolder,
											borderWidth,
											borderCustomColor,
											grayscaleOn,
											collageText,
											verticalAlignText,
											horizontalAlignText,
											defaultPriceText,
											setLastActiveId,
											priceMap,
											textSize,
											otcPosition,
											otcSize,
										}}
									/>
								) : (
									<MobileCollage
										{...{
											selectedNfts,
											numOfNftsPerCol,
											numOfNftsPerRow,
											displayWatermark,
											mbbHolder,
											bkgCustomColor,
											displayBorder,
											displayPrices,
											borderWidth,
											borderCustomColor,
											grayscaleOn,
											collageText,
											verticalAlignText,
											horizontalAlignText,
											defaultPriceText,
											priceMap,
											setLastActiveId,
											textSize,
											otcPosition,
											otcSize,
										}}
									/>
								)}

								<ResultsFooter
									{...{
										displayWatermark,
										setDisplayWatermark,
										mbbHolder,
										setMbbHolder,
										selectedNfts,
									}}
								/>
							</>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default CollageGeneratorPage;
