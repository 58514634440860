import { NFT_COLLECTIONS } from "../values/collections";
import { firebaseFunction } from "./firebase.service";

// const axios = require("axios");

/** Used to fetch NFT offchain data via firebase function which uses Helius API */
export const fetchNFTsFirebase = (wallets) => {
	return new Promise(async (resolve, reject) => {
		try {
			const params = { wallets };
			const response = await firebaseFunction("fetchNFTsViaWallets", params);

			resolve(response);
		} catch (error) {
			reject(error);
		}
	});
};

/** Used to fetch NFT offchain data via firebase function which uses Helius API */
export const buyOrderFirebase = (
	buyerWallet,
	buySignature,
	numNftsInCollage,
	failedOrder
) => {
	return new Promise(async (resolve, reject) => {
		try {
			const params = {
				buyerWallet,
				buySignature,
				numNftsInCollage,
				failedOrder,
			};

			const response = await firebaseFunction("addBuyOrder", params);

			resolve(response);
		} catch (error) {
			// console.log("fetchNFTsFirebase error - ", error);
			reject(error);
		}
	});
};

export const saveCollageLogFirebase = (
	userWallet,
	numNftsInCollage,
	mbbHolder
) => {
	return new Promise(async (resolve, reject) => {
		try {
			const params = {
				userWallet,
				numNftsInCollage,
				mbbHolder,
			};

			const response = await firebaseFunction("addCollageDownload", params);

			resolve(response);
		} catch (error) {
			reject(error);
		}
	});
};

/** Used to determine whether user's connected wallet address holds an MBB NFT or not */
export const fetchMbbHolderStatus = async (walletAddress) => {
	if (walletAddress === null) return null;
	const params = { walletAddress };

	return firebaseFunction("checkMbbHolder", params)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return null;
		});
};

/**
 * OLD implementation that fetched offchain NFT data one NFT at a time
 * Fetches NFT collection options based on inputted wallet.
 */
export const fetchNftCollections = (
	fullNFTsList,
	availableCollections = [],
	allNFTs = []
) => {
	return new Promise(async (resolve, reject) => {
		try {
			let verifiedNFTs = allNFTs;
			let verifiedCollections = availableCollections;

			let verifiedMintAddresses = [];

			for (let i = 0; i < fullNFTsList.length; i++) {
				let nftVerifiedCreatorAddress = "";
				for (let k = 0; k < fullNFTsList[i]?.creators.length; k++) {
					if (fullNFTsList[i]?.creators[k].verified) {
						nftVerifiedCreatorAddress =
							fullNFTsList[i]?.creators[k]?.address?.toBase58();
						break;
					}
				}

				const verifiedNFTCol = NFT_COLLECTIONS.some(
					(nft) => nft.verifiedCreatorAddress === nftVerifiedCreatorAddress
				);

				if (verifiedNFTCol) {
					if (verifiedCollections[nftVerifiedCreatorAddress]) {
						verifiedCollections[nftVerifiedCreatorAddress] += 1;
					} else {
						verifiedCollections[nftVerifiedCreatorAddress] = 1;
					}

					fullNFTsList[i].verifiedCreatorAddress = nftVerifiedCreatorAddress;

					if (fullNFTsList[i]?.mintAddress?.toBase58()) {
						verifiedMintAddresses.push(
							fullNFTsList[i]?.mintAddress?.toBase58()
						);
					}

					// // Bottleneck is fetching the offchain data... -> get via hyperspace?
					fullNFTsList[i].collectionDetails = await fetchOffchainData(
						fullNFTsList[i].uri
					);
					verifiedNFTs.push(fullNFTsList[i]);
				}
				// 	else {
				// 		// OTHER NFTs (non-verified)
				// 		if (verifiedCollections["other"]) {
				// 			verifiedCollections["other"] += 1;
				// 		} else {
				// 			verifiedCollections["other"] = 1;
				// 		}

				// 		fullNFTsList[i].verifiedCreatorAddress = "other";

				// 		if (fullNFTsList[i]?.mintAddress?.toBase58()) {
				// 			verifiedMintAddresses.push(
				// 				fullNFTsList[i]?.mintAddress?.toBase58()
				// 			);
				// 		}
				// 		try {
				// 			// Bottleneck is fetching the offchain data... -> get via hyperspace?
				// 			fullNFTsList[i].collectionDetails = await fetchOffchainData(
				// 				fullNFTsList[i].uri
				// 			);
				// 		} catch (error) {
				// 			fullNFTsList[i].collectionDetails = null;
				// 		}
				// 		verifiedNFTs.push(fullNFTsList[i]);
				// 	}
			}
			resolve({ verifiedCollections, verifiedNFTs });
		} catch (error) {
			reject("error - ", error);
		}
	});
};

/** PRIVATE FUNCTIONS */

/** OLD Implementation: Used to fetch the offchain data tied to each NFT. */
export const fetchOffchainData = (uri) => {
	return new Promise((resolve, reject) => {
		try {
			const offchainData = fetch(uri)
				.then((response) => response.json())
				.then((data) => {
					return data;
				});
			resolve(offchainData);
		} catch (error) {
			resolve(null);
			return;
			// reject("error - ", error);
		}
	});
};

/** Used to fetch NFT offchain data via firebase function which uses Helius API */
// export const fetchNFTsFirebaseOld = async (metaplexNFTs) => {
// 	for (let i = 0; i < metaplexNFTs.length; i++) {
// 		if (metaplexNFTs[i]?.creators) {
// 			for (let k = 0; k < metaplexNFTs[i]?.creators?.length; k++) {
// 				if (metaplexNFTs[i]?.creators[k]?.address) {
// 					metaplexNFTs[i].creators[k].address =
// 						metaplexNFTs[i]?.creators[k]?.address?.toBase58();
// 				}
// 			}
// 		}
// 		if (metaplexNFTs[i]?.mintAddress) {
// 			metaplexNFTs[i].mintAddress = metaplexNFTs[i].mintAddress?.toBase58();
// 		}
// 	}
// 	const params = { metaplexNFTs };

// 	return await firebaseFunction("fetchNFTs", params)
// 		.then((response) => {
// 			return response;
// 		})
// 		.catch((error) => {
// 			return null;
// 		});
// };
