import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../../services/utils/Dimensions";
import {
	fetchMbbHolderStatus,
	saveCollageLogFirebase,
} from "../../../services/fetchNFTs.service";

/** Collage Image Saving */
import html2canvas from "html2canvas";

/** Third Party Libraries */
import { Oval, ProgressBar } from "react-loader-spinner";
import ReactGA from "react-ga4";

/** Web3 */
import { useConnection } from "@solana/wallet-adapter-react";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import {
	Transaction,
	SystemProgram,
	PublicKey,
	LAMPORTS_PER_SOL,
} from "@solana/web3.js";

/** Icons */
import { IoMdLock, IoIosCheckmarkCircle } from "react-icons/io";
// TODO: change this to react-icon
import DownloadIcon from "../../../assets/images/download-image-icon.png";
import { DEFAULT_VALUES } from "../../../values/constants";
import { buyOrderFirebase } from "../../../services/fetchNFTs.service";

export const ResultsFooter = ({
	setDisplayWatermark,
	mbbHolder,
	setMbbHolder,
	selectedNfts,
	displayWatermark,
}) => {
	/** Web3 connections */
	const { connection } = useConnection();
	const { connected, publicKey, sendTransaction } = useWallet();

	/** Display options */
	const [userSolBalance, setUserSolBalance] = useState(0);
	const [purchased, setPurchased] = useState(false);
	const [purchaseLoading, setPurchaseLoading] = useState(false);

	const [solScanLink, setSolScanLink] = useState(null);
	const [errorPurchasingMsg, setErrorPurchasingMsg] = useState(null);
	const [loadingCollageToClipbaord, setLoadingCollageToClipboard] =
		useState(false);

	const windowDimensions = useWindowDimensions();
	const [loadingDownloadImage, setLoadingDownloadImage] = useState(false);

	/* Used to ensure the user has enough SOL to make the purchase */
	useEffect(() => {
		getSolBalance();
		getMbbStatus();
	}, [publicKey, connection]);

	/** Get the user wallet's SOL balance */
	const getSolBalance = async () => {
		if (publicKey && connection) {
			connection.getBalance(publicKey).then((balance) => {
				setUserSolBalance(balance / LAMPORTS_PER_SOL);
			});
		} else {
			setUserSolBalance(0);
		}
	};

	const getMbbStatus = async () => {
		if (connection && publicKey?.toBase58() !== null) {
			const result = await fetchMbbHolderStatus(publicKey?.toBase58());
			if (result === null) {
				setMbbHolder(false);
			} else {
				setMbbHolder(result.data);
			}
		}
	};

	/** Used to build the collage into a canvas and
	 * download the collage to the user's device */
	const exportCollage = async (imageFileName) => {
		try {
			setLoadingDownloadImage(true);
			const el = document.getElementById("collage");

			const canvas = await html2canvas(el, {
				useCORS: true,
				scale: 4,
				height: el.offsetHeight - 0.1,
			});
			const image = canvas.toDataURL("image/png", 1.0);
			downloadImage(image, imageFileName);
		} catch (error) {
			setLoadingDownloadImage(false);
			const savedTxn = `${solScanLink}, Export collage error.`;

			buyOrderFirebase(publicKey?.toBase58(), savedTxn, selectedNfts, true);
			setErrorPurchasingMsg(
				"Download Error, Please contact the MBB team: " + error?.message
			);
		}
	};
	/** Downloads the image to the user's device */
	const downloadImage = (blob, fileName) => {
		try {
			const fakeLink = window.document.createElement("a");
			fakeLink.style = "display:none;";
			fakeLink.download = fileName;

			fakeLink.href = blob;

			document.body.appendChild(fakeLink);
			fakeLink.click();
			document.body.removeChild(fakeLink);

			fakeLink.remove();
			setLoadingDownloadImage(false);

			setTimeout(() => {
				setPurchased(false);
				setDisplayWatermark(true);
			}, 1000);
		} catch (error) {
			const savedTxn = `${solScanLink}, Download image error.`;

			buyOrderFirebase(publicKey?.toBase58(), savedTxn, selectedNfts, true);
			setLoadingDownloadImage(false);
			setErrorPurchasingMsg(
				"Download Error, Please contact the MBB team: " + error?.message
			);
		}
	};

	/** Used to purchase a collage */
	const buyCollage = async () => {
		try {
			setErrorPurchasingMsg(null);
			const START_TIME = new Date();

			const blockhashResponse = await connection.getLatestBlockhashAndContext(
				"finalized"
			);
			const lastValidHeight = blockhashResponse.value.lastValidBlockHeight;

			const txn = new Transaction().add(
				SystemProgram.transfer({
					fromPubkey: publicKey,
					toPubkey: new PublicKey(DEFAULT_VALUES.MERCHANT_ADDRESS),
					lamports: LAMPORTS_PER_SOL * DEFAULT_VALUES.SOL_COLLAGE_PRICE,
				})
			);

			// Waits for the user to approve the purchase to send the transaction
			const txId = await sendTransaction(txn, connection);
			setPurchaseLoading(true);

			// Checks transaction succeeded onchain
			const transactionOnchain = await didTransactionSucceed(
				txId,
				START_TIME,
				lastValidHeight
			);

			if (transactionOnchain === true) {
				const validTxnInstructions = await validTransactionParams(txId);
				if (validTxnInstructions === true) {
					//	console.log("Transaction verification complete");
					// Add analytics to keep track of buys
					ReactGA.event({
						category: "collage_buy",
						action: "buy",
						total_nfts: selectedNfts?.length,
						buyer_wallet: publicKey?.toBase58(),
						buy_signature: txId,
						time_bought: new Date().toISOString().slice(0, 10),
					});

					buyOrderFirebase(publicKey?.toBase58(), txId, selectedNfts, false);

					setDisplayWatermark(false);
					setPurchased(true);
					setPurchaseLoading(false);

					// Wait until all images watermarks are updated?
					let watermarkTimer = null;
					let intervals = 0;

					const checkWatermark = function () {
						// Check if all watermarks are removed
						const collageImages =
							document.getElementsByClassName("collageImage");

						let noWatermarks = true;
						for (let i = 0; i < collageImages.length; i++) {
							if (collageImages[i]?.src?.includes("i-mbb-watermark")) {
								// Still has watermark on some images
								noWatermarks = false;
								break;
							}
						}
						intervals += 1;

						if (noWatermarks) {
							clearInterval(watermarkTimer);
							exportCollage("mbb-collage.png");
							return;
						}
						if (intervals > 6) {
							// Clear interval after 6 tries
							clearInterval(watermarkTimer);
							exportCollage("mbb-collage.png");
						}
					};

					setTimeout(() => {
						watermarkTimer = setInterval(checkWatermark, 2000);
					}, 500);

					return;
				}
			}
		} catch (error) {
			setPurchaseLoading(false);
			setDisplayWatermark(true);
		}
	};

	/** Used to wait/sleep before re-looping through a set of code */
	const sleep = (ms) => {
		return new Promise((resolve) => setTimeout(resolve, ms));
	};

	/** Used to keep track of whether the blockchain is expired */
	async function isBlockhashExpired(connection, lastValidBlockHeight) {
		let currentBlockHeight = await connection.getBlockHeight("finalized");
		// console.log("                           ");
		// console.log("Current Block height:             ", currentBlockHeight);
		// console.log(
		// 	"Last Valid Block height - 150:     ",
		// 	lastValidBlockHeight - 150
		// );
		// console.log("--------------------------------------------");
		// console.log(
		// 	"Difference:                      ",
		// 	currentBlockHeight - (lastValidBlockHeight - 150)
		// ); // If Difference is positive, blockhash has expired.
		// console.log("                           ");
		return currentBlockHeight > lastValidBlockHeight - 150;
	}

	/** Used to determine whether the transaction succeeded onchain */
	async function didTransactionSucceed(txId, START_TIME, lastValidHeight) {
		// Check transaction status and blockhash status until the transaction succeeds or blockhash expires
		let hashExpired = false;
		let txSuccess = false;
		while (!hashExpired && !txSuccess) {
			const { value: status } = await connection.getSignatureStatus(txId);

			// Break loop if transaction has succeeded
			if (
				status &&
				(status.confirmationStatus === "confirmed" || "finalized")
			) {
				txSuccess = true;
				const endTime = new Date();
				const elapsed = (endTime.getTime() - START_TIME.getTime()) / 1000;
				//console.log(`Transaction Success. Elapsed time: ${elapsed} seconds.`);
				//console.log(`https://explorer.solana.com/tx/${txId}?cluster=mainnet`);
				setSolScanLink("https://solscan.io/tx/" + txId);
				return true;
			}

			hashExpired = await isBlockhashExpired(connection, lastValidHeight);

			// Break loop if blockhash has expired
			if (hashExpired) {
				const endTime = new Date();
				const elapsed = (endTime.getTime() - START_TIME.getTime()) / 1000;
				// console.log(`Blockhash has expired. Elapsed time: ${elapsed} seconds.`);

				// Throw an error if blockhash has expired.
				setErrorPurchasingMsg(
					`Blockhash has expired. Elapsed time: ${elapsed} seconds. Please try again.`
				);
				setPurchaseLoading(false);
				setDisplayWatermark(true);
				const savedTxn = `${txId}, Hash expired - Elapsed time: ${elapsed} seconds.`;
				buyOrderFirebase(publicKey?.toBase58(), savedTxn, selectedNfts, true);
				return false;
			}

			// Check again after 2.5 sec
			await sleep(2500);
		}
	}

	async function validTransactionParams(txId) {
		let attempts = 0;

		let parsedTxn = null;
		let receiver = null;
		let lamportsAmount = null;

		do {
			parsedTxn = await connection.getParsedTransaction(txId);

			// Loop through instructions to find one with destination and lamports info
			let txnInfo = null;
			if (
				parsedTxn?.transaction?.message?.instructions &&
				parsedTxn?.transaction?.message?.instructions?.length > 0
			) {
				for (
					let k = 0;
					k < parsedTxn?.transaction?.message?.instructions.length;
					k++
				) {
					let tempTxnInfo =
						parsedTxn?.transaction?.message?.instructions[k]?.parsed?.info;
					if (tempTxnInfo?.destination && tempTxnInfo?.lamports) {
						txnInfo = tempTxnInfo;
						break;
					}
				}
			}

			receiver = txnInfo?.destination;
			lamportsAmount = txnInfo?.lamports;

			if (
				receiver === DEFAULT_VALUES.MERCHANT_ADDRESS &&
				lamportsAmount >=
					(DEFAULT_VALUES.SOL_COLLAGE_PRICE - 0.001) * LAMPORTS_PER_SOL
			) {
				return true;
			}
			attempts += 1;
			await sleep(2500);
		} while (
			(parsedTxn === null || receiver === null || lamportsAmount === null) &&
			attempts <= 5
		);

		if (
			(receiver !== DEFAULT_VALUES.MERCHANT_ADDRESS ||
				lamportsAmount <
					(DEFAULT_VALUES.SOL_COLLAGE_PRICE - 0.001) * LAMPORTS_PER_SOL) &&
			parsedTxn &&
			txId
		) {
			// Throw an error if blockhash has expired.
			setErrorPurchasingMsg(
				`Transaction verification failed. Invalid receiver address or purchase amount. Please contact the MBB team.`
			);
			setPurchaseLoading(false);
			setDisplayWatermark(true);

			// Pass more information on why the txn failed
			let txIdSaved = `txId: ${txId}, receiver: ${receiver}, lamportsAmount: ${lamportsAmount}`;

			buyOrderFirebase(publicKey?.toBase58(), txIdSaved, selectedNfts, true);
			return false;
		} else {
			return true;
		}
	}

	return (
		<>
			<div className="py-2 mb-16 lg:mb-32 px-4 lg:m-6 flex flex-col items-center justify-center">
				<div className="flex flex-row text-center justify-center items-center flex-wrap">
					{!purchased && (
						<button
							disabled={
								!publicKey ||
								userSolBalance < 0.0101 ||
								purchaseLoading ||
								mbbHolder
							}
							className={`${
								mbbHolder ? "bg-green-600" : "bg-navy-blue"
							} block rounded-xl py-2 px-4 mr-4 mb-2 truncate text-white font-cubano disabled:opacity-60`}
							onClick={buyCollage}
						>
							<div className="flex flex-row items-center">
								{mbbHolder ? (
									<IoIosCheckmarkCircle
										size={20}
										color="#FFF"
										className="mr-2"
									/>
								) : (
									<IoMdLock size={20} color="#FFF" className="mr-2" />
								)}
								<p className="whitespace-normal">
									{mbbHolder
										? "MBB Holders Get Free Collages! Push Save Collage Image to Download"
										: "Remove Watermarks & Save Collage For 0.01 SOL"}
								</p>
							</div>
						</button>
					)}
					<div className="mb-2">
						<WalletMultiButton />
					</div>
				</div>
				{!purchaseLoading &&
					!purchased &&
					!errorPurchasingMsg &&
					!mbbHolder && (
						<p className="text-pink font-roboto font-medium text-sm mb-2">
							FREE for MBB Holders. Just connect your wallet!
						</p>
					)}
				{purchaseLoading && (
					<Oval
						height={28}
						width={28}
						color="#fff"
						wrapperStyle={{
							//marginRight: "12px",
							marginTop: "4px",
							marginBottom: "8px",
						}}
						wrapperClass=""
						visible={true}
						ariaLabel="oval-loading"
						secondaryColor="#6276C9"
						strokeWidth={2}
						strokeWidthSecondary={2}
					/>
				)}
				{errorPurchasingMsg && !mbbHolder && (
					<p className=" text-red-600 font-roboto font-medium text-sm my-2">
						Error Purchasing: {errorPurchasingMsg}
					</p>
				)}
				{!solScanLink && connected && !mbbHolder && (
					<p
						className={`${
							userSolBalance >= 0.0101 ? "text-green-500" : "text-red-600"
						} font-roboto font-medium text-sm mb-4`}
					>
						Wallet balance:{" "}
						{userSolBalance ? userSolBalance?.toFixed(2) + " SOL" : "-"}
					</p>
				)}

				{solScanLink && (
					<>
						<p className="my-1">
							Purchase & Download{" "}
							{displayWatermark
								? "Successful! 🎉"
								: "In Progress... Please Do NOT Leave This Page"}
						</p>
						{!displayWatermark && (
							<p className="text-pink font-roboto font-medium text-sm mb-2">
								Please wait a few moments for the watermarks to be removed and
								the image to download. This may take a minute...
							</p>
						)}
						<a
							className="text-xs font-medium underline text-blue-400 mb-6"
							href={solScanLink}
							target="_blank"
						>
							View SolScan txn here.
						</a>
					</>
				)}

				<button
					onClick={() => {
						exportCollage("mbb-collage.png");

						saveCollageLogFirebase(
							publicKey?.toBase58(),
							selectedNfts,
							mbbHolder
						);

						ReactGA.event({
							category: mbbHolder ? "mbb_download" : "watermark_download",
							action: mbbHolder ? "mbb_download" : "watermark_download",
							total_nfts: selectedNfts?.length,
							time_bought: new Date().toISOString().slice(0, 10),
						});
					}}
					disabled={purchased || purchaseLoading}
					className="block rounded-xl py-2 px-4 mt-2 mb-4 truncate bg-pink text-white font-cubano disabled:opacity-60"
				>
					<div className="flex flex-row">
						<img
							src={DownloadIcon}
							width={20}
							height={20}
							alt="Download collage icon"
							className="mr-2 object-contain"
							style={{ whiteSpace: "pre-line" }}
						/>
						<p>
							{mbbHolder ? "Save Collage Image" : "Save Collage w/ Watermarks"}
						</p>
					</div>
				</button>
				{loadingDownloadImage && (
					<ProgressBar
						height="70"
						width="70"
						ariaLabel="progress-bar-loading"
						wrapperStyle={{
							display: "flex",
							justifyContent: "center",
						}}
						borderColor="#e41e8e"
						barColor="#e41e8e"
					/>
				)}
				{loadingCollageToClipbaord && (
					<ProgressBar
						height="70"
						width="70"
						ariaLabel="progress-bar-loading"
						wrapperStyle={{
							display: "flex",
							justifyContent: "center",
						}}
						borderColor="#7FAAF8"
						barColor="#7FAAF8"
					/>
				)}
				{windowDimensions.width <= 768 && (
					<p
						className="pt-6 text-md font-roboto font-semibold text-dark-blue"
						style={{
							maxWidth: 350,
							flexWrap: "wrap",
							whiteSpace: "pre-line",
						}}
					>
						Note: Downloading is not supported on Firefox or the Phantom mobile
						app. {"\n"}Please try in Safari or the Chrome app.
					</p>
				)}
			</div>
		</>
	);
};

/*
 * Check specific props to determine
 * if component will re-render
 */
const arePropsEqual = (prevProps, nextProps) => {
	const mbbHolder = prevProps?.mbbHolder === nextProps?.mbbHolder;
	const selectedNfts = prevProps?.selectedNfts === nextProps?.selectedNfts;
	const displayWatermark =
		prevProps?.displayWatermark === nextProps?.displayWatermark;

	return mbbHolder && selectedNfts && displayWatermark;
};

export default React.memo(ResultsFooter, arePropsEqual);
